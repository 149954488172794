<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Layout" : "Cadastrar Layout" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      selev-select="off"
      @submit.prevent
    >
      <v-card :class="`mb-${id ? '5' : '15'}`">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Ônibus</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="9">
              <span class="primary--text">Descrição *</span>
              <v-text-field
                v-model="dados.descricao"
                :rules="fieldRules"
                required
                placeholder="Descrição"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Andares</span>
              <v-select
                :items="['1', '2']"
                :disabled="show"
                v-model="dados.andares"
                placeholder="Andares"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>

    <v-card
      class="bus ml-3 mb-15"
      tile
      style="border-right: 5px solid #2196f3"
      v-if="show == false && id"
    >
      <div class="total">
        <div class="motorist">
          <v-img
            src="@/assets/frenteBus.svg"
            aspect-ratio="1.7"
            width="210px"
            height="345px"
            style="position: relative; left: -32px; top: -50px"
          ></v-img>
        </div>
        <div class="intro">
          <div>
            <div class="linhas">
              <span v-for="(n, i) in poltronas" :key="i">
                <v-menu
                  ref="menu"
                  z-index="9999"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  v-if="i > 44 && i <= 59"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      style="position: relative"
                      class="mx-1"
                      dark
                      color="transparent"
                      elevation="0"
                      aspect-ratio="1"
                      width="45"
                      height="45"
                    >
                      <p class="text" v-if="poltronas[i].numero > 0">
                        {{ poltronas[i].numero }}
                      </p>
                      <div
                        v-html="$store.getters.getPoltrona"
                        :style="`position:absolute;fill:${poltronas[i].legenda}`"
                      ></div>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-container fluid>
                      <v-row align="center" style="margin-bottom: 30px">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Número"
                            v-mask="'99'"
                            v-model="poltronas[i].numero"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row align="center" class="mb-3">
                          <v-col class="d-flex" cols="12" sm="12">
                            <v-select
                              label="Tipo de Serviço"
                              :items="tiposervicos"
                              item-text="tipo_servico"
                              item-value="id"
                              v-model="poltronas[i].tiposervico"
                            ></v-select>
                          </v-col>
                        </v-row> -->
                      <v-footer absolute padless>
                        <v-btn
                          @click="
                            [
                              salvarPoltrona({
                                numero: poltronas[i].numero,
                                id_tipo_servico: poltronas[i].tiposervico,
                                id: poltronas[i].id,
                              }),
                            ]
                          "
                          text
                          color="primary"
                          >CONFIRMAR</v-btn
                        >
                        <v-btn
                          @click="[dropPoltrona(poltronas[i].id, i)]"
                          text
                          color="error"
                          >EXCLUIR</v-btn
                        >
                      </v-footer>
                    </v-container>
                  </v-card>
                </v-menu>
              </span>
            </div>

            <div class="linhas aprox">
              <span v-for="(n, i) in poltronas" :key="i">
                <v-menu
                  ref="menu"
                  z-index="9999"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  v-if="i > 29 && i <= 44"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      style="position: relative"
                      class="mx-1"
                      dark
                      color="transparent"
                      elevation="0"
                      aspect-ratio="1"
                      width="45"
                      height="45"
                    >
                      <p class="text" v-if="poltronas[i].numero > 0">
                        {{ poltronas[i].numero }}
                      </p>
                      <div
                        v-html="$store.getters.getPoltrona"
                        :style="`position:absolute;fill:${poltronas[i].legenda}`"
                      ></div>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-container fluid>
                      <v-row align="center" style="margin-bottom: 30px">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Número"
                            v-mask="'99'"
                            v-model="poltronas[i].numero"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row align="center" class="mb-3">
                          <v-col class="d-flex" cols="12" sm="12">
                            <v-select
                              label="Tipo de Serviço"
                              :items="tiposervicos"
                              item-text="tipo_servico"
                              item-value="id"
                              v-model="poltronas[i].tiposervico"
                            ></v-select>
                          </v-col>
                        </v-row> -->
                      <v-footer absolute padless>
                        <v-btn
                          @click="
                            [
                              salvarPoltrona({
                                numero: poltronas[i].numero,
                                id_tipo_servico: poltronas[i].tiposervico,
                                id: poltronas[i].id,
                              }),
                            ]
                          "
                          text
                          color="primary"
                          >CONFIRMAR</v-btn
                        >
                        <v-btn
                          @click="[dropPoltrona(poltronas[i].id, i)]"
                          text
                          color="error"
                          >EXCLUIR</v-btn
                        >
                      </v-footer>
                    </v-container>
                  </v-card>
                </v-menu>
              </span>
            </div>
          </div>
          <div class="aprox">
            <div class="linhas">
              <span v-for="(n, i) in poltronas" :key="i">
                <v-menu
                  ref="menu"
                  z-index="9999"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  v-if="i > 14 && i <= 29"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      style="position: relative"
                      class="mx-1"
                      dark
                      color="transparent"
                      elevation="0"
                      aspect-ratio="1"
                      width="45"
                      height="45"
                    >
                      <p class="text" v-if="poltronas[i].numero > 0">
                        {{ poltronas[i].numero }}
                      </p>
                      <div
                        v-html="$store.getters.getPoltrona"
                        :style="`position:absolute;fill:${poltronas[i].legenda}`"
                      ></div>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-container fluid>
                      <v-row align="center" style="margin-bottom: 30px">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Número"
                            v-mask="'99'"
                            v-model="poltronas[i].numero"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row align="center" class="mb-3">
                          <v-col class="d-flex" cols="12" sm="12">
                            <v-select
                              label="Tipo de Serviço"
                              :items="tiposervicos"
                              item-text="tipo_servico"
                              item-value="id"
                              v-model="poltronas[i].tiposervico"
                            ></v-select>
                          </v-col>
                        </v-row> -->
                      <v-footer absolute padless>
                        <v-btn
                          @click="
                            [
                              salvarPoltrona({
                                numero: poltronas[i].numero,
                                id_tipo_servico: poltronas[i].tiposervico,
                                id: poltronas[i].id,
                              }),
                            ]
                          "
                          text
                          color="primary"
                          >CONFIRMAR</v-btn
                        >
                        <v-btn
                          @click="[dropPoltrona(poltronas[i].id, i)]"
                          text
                          color="error"
                          >EXCLUIR</v-btn
                        >
                      </v-footer>
                    </v-container>
                  </v-card>
                </v-menu>
              </span>
            </div>
            <div class="linhas aprox">
              <span v-for="(n, i) in poltronas" :key="i">
                <v-menu
                  ref="menu"
                  z-index="9999"
                  :close-on-content-click="menu"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  v-if="i <= 14"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      style="position: relative"
                      class="mx-1"
                      dark
                      color="transparent"
                      elevation="0"
                      aspect-ratio="1"
                      width="45"
                      height="45"
                    >
                      <p class="text" v-if="poltronas[i].numero > 0">
                        {{ poltronas[i].numero }}
                      </p>
                      <div
                        v-html="$store.getters.getPoltrona"
                        :style="`position:absolute;fill:${poltronas[i].legenda}`"
                      ></div>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-container fluid>
                      <v-row align="center" style="margin-bottom: 30px">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Número"
                            v-mask="'99'"
                            v-model="poltronas[i].numero"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row align="center" class="mb-3">
                          <v-col class="d-flex" cols="12" sm="12">
                            <v-select
                              label="Tipo de Serviço"
                              :items="tiposervicos"
                              item-text="tipo_servico"
                              item-value="id"
                              v-model="poltronas[i].tiposervico"
                            ></v-select>
                          </v-col>
                        </v-row> -->
                      <v-footer absolute padless>
                        <v-btn
                          @click="
                            [
                              salvarPoltrona({
                                numero: poltronas[i].numero,
                                id_tipo_servico: poltronas[i].tiposervico,
                                id: poltronas[i].id,
                              }),
                            ]
                          "
                          text
                          color="primary"
                          >CONFIRMAR</v-btn
                        >
                        <v-btn
                          @click="[dropPoltrona(poltronas[i].id, i)]"
                          text
                          color="error"
                          >EXCLUIR</v-btn
                        >
                      </v-footer>
                    </v-container>
                  </v-card>
                </v-menu>
              </span>
            </div>
          </div>
        </div>
        <v-icon
          class="mr-2"
          large
          style="position: relative; right: 20px"
          @click="show = true"
          v-if="dados.andares == 2"
          >mdi-arrow-up-bold</v-icon
        >
      </div>
    </v-card>
    <!-- segundo andar -->
    <v-card
      class="bus ml-3 mb-15"
      tile
      style="border-right: 5px solid #2196f3"
      v-if="dados.andares == 2 && show == true"
    >
      <div class="total">
        <div class="motorist">
          <v-img
            src="@/assets/frenteBusNoMotorist.svg"
            aspect-ratio="1.7"
            width="210px"
            height="345px"
            style="position: relative; left: -32px; top: -50px"
          ></v-img>
        </div>
        <div class="intro">
          <div>
            <div class="linhas">
              <span v-for="(n, i) in poltronas" :key="i">
                <v-menu
                  ref="menu"
                  z-index="9999"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  v-if="i > 104 && i <= 119"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      style="position: relative"
                      class="mx-1"
                      dark
                      color="transparent"
                      elevation="0"
                      aspect-ratio="1"
                      width="45"
                      height="45"
                    >
                      <p class="text" v-if="poltronas[i].numero > 0">
                        {{ poltronas[i].numero }}
                      </p>
                      <div
                        v-html="$store.getters.getPoltrona"
                        :style="`position:absolute;fill:${poltronas[i].legenda}`"
                      ></div>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-container fluid>
                      <v-row align="center" style="margin-bottom: 30px">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Número"
                            v-mask="'99'"
                            v-model="poltronas[i].numero"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row align="center" class="mb-3">
                          <v-col class="d-flex" cols="12" sm="12">
                            <v-select
                              label="Tipo de Serviço"
                              :items="tiposervicos"
                              item-text="tipo_servico"
                              item-value="id"
                              v-model="poltronas[i].tiposervico"
                            ></v-select>
                          </v-col>
                        </v-row> -->
                      <v-footer absolute padless>
                        <v-btn
                          @click="
                            [
                              salvarPoltrona({
                                numero: poltronas[i].numero,
                                id_tipo_servico: poltronas[i].tiposervico,
                                id: poltronas[i].id,
                              }),
                            ]
                          "
                          text
                          color="primary"
                          >CONFIRMAR</v-btn
                        >
                        <v-btn
                          @click="[dropPoltrona(poltronas[i].id, i)]"
                          text
                          color="error"
                          >EXCLUIR</v-btn
                        >
                      </v-footer>
                    </v-container>
                  </v-card>
                </v-menu>
              </span>
            </div>
            <div class="linhas aprox">
              <span v-for="(n, i) in poltronas" :key="i">
                <v-menu
                  ref="menu"
                  z-index="9999"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  v-if="i > 89 && i <= 104"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      style="position: relative"
                      class="mx-1"
                      dark
                      color="transparent"
                      elevation="0"
                      aspect-ratio="1"
                      width="45"
                      height="45"
                    >
                      <p class="text" v-if="poltronas[i].numero > 0">
                        {{ poltronas[i].numero }}
                      </p>
                      <div
                        v-html="$store.getters.getPoltrona"
                        :style="`position:absolute;fill:${poltronas[i].legenda}`"
                      ></div>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-container fluid>
                      <v-row align="center" style="margin-bottom: 30px">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Número"
                            v-mask="'99'"
                            v-model="poltronas[i].numero"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row align="center" class="mb-3">
                          <v-col class="d-flex" cols="12" sm="12">
                            <v-select
                              label="Tipo de Serviço"
                              :items="tiposervicos"
                              item-text="tipo_servico"
                              item-value="id"
                              v-model="poltronas[i].tiposervico"
                            ></v-select>
                          </v-col>
                        </v-row> -->
                      <v-footer absolute padless>
                        <v-btn
                          @click="
                            [
                              salvarPoltrona({
                                numero: poltronas[i].numero,
                                id_tipo_servico: poltronas[i].tiposervico,
                                id: poltronas[i].id,
                              }),
                            ]
                          "
                          text
                          color="primary"
                          >CONFIRMAR</v-btn
                        >
                        <v-btn
                          @click="[dropPoltrona(poltronas[i].id, i)]"
                          text
                          color="error"
                          >EXCLUIR</v-btn
                        >
                      </v-footer>
                    </v-container>
                  </v-card>
                </v-menu>
              </span>
            </div>
          </div>
          <div class="aprox">
            <div class="linhas">
              <span v-for="(n, i) in poltronas" :key="i">
                <v-menu
                  ref="menu"
                  z-index="9999"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  v-if="i > 74 && i <= 89"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      style="position: relative"
                      class="mx-1"
                      dark
                      color="transparent"
                      elevation="0"
                      aspect-ratio="1"
                      width="45"
                      height="45"
                    >
                      <p class="text" v-if="poltronas[i].numero > 0">
                        {{ poltronas[i].numero }}
                      </p>
                      <div
                        v-html="$store.getters.getPoltrona"
                        :style="`position:absolute;fill:${poltronas[i].legenda}`"
                      ></div>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-container fluid>
                      <v-row align="center" style="margin-bottom: 30px">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Número"
                            v-mask="'99'"
                            v-model="poltronas[i].numero"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row align="center" class="mb-3">
                          <v-col class="d-flex" cols="12" sm="12">
                            <v-select
                              label="Tipo de Serviço"
                              :items="tiposervicos"
                              item-text="tipo_servico"
                              item-value="id"
                              v-model="poltronas[i].tiposervico"
                            ></v-select>
                          </v-col>
                        </v-row> -->
                      <v-footer absolute padless>
                        <v-btn
                          @click="
                            [
                              salvarPoltrona({
                                numero: poltronas[i].numero,
                                id_tipo_servico: poltronas[i].tiposervico,
                                id: poltronas[i].id,
                              }),
                            ]
                          "
                          text
                          color="primary"
                          >CONFIRMAR</v-btn
                        >
                        <v-btn
                          @click="[dropPoltrona(poltronas[i].id, i)]"
                          text
                          color="error"
                          >EXCLUIR</v-btn
                        >
                      </v-footer>
                    </v-container>
                  </v-card>
                </v-menu>
              </span>
            </div>
            <div class="linhas aprox">
              <span v-for="(n, i) in poltronas" :key="i">
                <v-menu
                  ref="menu"
                  z-index="9999"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  v-if="i > 59 && i <= 74"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      style="position: relative"
                      class="mx-1"
                      dark
                      color="transparent"
                      elevation="0"
                      aspect-ratio="1"
                      width="45"
                      height="45"
                    >
                      <p class="text" v-if="poltronas[i].numero > 0">
                        {{ poltronas[i].numero }}
                      </p>
                      <div
                        v-html="$store.getters.getPoltrona"
                        :style="`position:absolute;fill:${poltronas[i].legenda}`"
                      ></div>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-container fluid>
                      <v-row align="center" style="margin-bottom: 30px">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Número"
                            v-mask="'99'"
                            v-model="poltronas[i].numero"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row align="center" class="mb-3">
                          <v-col class="d-flex" cols="12" sm="12">
                            <v-select
                              label="Tipo de Serviço"
                              :items="tiposervicos"
                              item-text="tipo_servico"
                              item-value="id"
                              v-model="poltronas[i].tiposervico"
                            ></v-select>
                          </v-col>
                        </v-row> -->
                      <v-footer absolute padless>
                        <v-btn
                          @click="
                            [
                              salvarPoltrona({
                                numero: poltronas[i].numero,
                                id_tipo_servico: poltronas[i].tiposervico,
                                id: poltronas[i].id,
                              }),
                            ]
                          "
                          text
                          color="primary"
                          >CONFIRMAR</v-btn
                        >
                        <v-btn
                          @click="[dropPoltrona(poltronas[i].id, i)]"
                          text
                          color="error"
                          >EXCLUIR</v-btn
                        >
                      </v-footer>
                    </v-container>
                  </v-card>
                </v-menu>
              </span>
            </div>
          </div>
        </div>
        <v-icon
          class="mr-2"
          style="position: relative; right: 20px"
          large
          @click="show = false"
          v-if="dados.andares == 2"
          >mdi-arrow-down-bold</v-icon
        >
      </div>
    </v-card>
    <!-- segundo andar -->

    <!-- <v-card class="mt-6" v-if="id">
        <v-container fluid>
          <v-row align="center">
            <v-col class="d-flex" cols="12" sm="2">
              <h2 class="font-weight-light text-uppercase">Legendas</h2>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col class="d-flex" cols="12" sm="9">
              <v-sheet elevation="0" width="100%">
                <v-chip-group style="color: #fff" dark mandatory>
                  <v-chip
                    large
                    v-for="(tipoServico, i) in tiposervicos"
                    :key="i"
                    :color="tipoServico.legenda"
                    dark
                    label
                    class="mr-2 mt-2"
                    >{{ tipoServico.tipo_servico }}</v-chip
                  >
                </v-chip-group>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card> -->

    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      menu: false,
      loading: false,
      show: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        andares: "",
        descricao: "",
      },
      poltronas: [],
      tiposervicos: [],
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loader();
      if (this.id) {
        this.$store.dispatch("activeLoader", true);
        this.$http
          .get(`/layouts/${this.id}`)
          .then((r) => {
            this.dados = r.data[0];
            this.getBus();
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    dropPoltrona(id, i) {
      this.$http.post(`/layouts/poltronas/delete/${id}`).then((r) => {
        console.log(r.data);
        this.poltronas[i].legenda = "#000";
        this.getBus();
        this.$store.dispatch("activeLoader", false);
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvarPoltrona(data) {
      this.$http
        .post(`/layouts/poltronas`, data)
        .then((r) => {
          this.getBus();
          console.log(r.data);
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    getBus() {
      this.$http
        .get(`/layouts/bus/${this.id}`)
        .then((r) => {
          this.poltronas = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loader() {
      this.$http
        .get(`/tiposservico`)
        .then((r) => {
          this.tiposervicos = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/layouts/update/${this.id}`, this.dados) // link da API
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push(
                  "/cadastros-operacionais/onibus/layout/gerenciar"
                ); // Link da rota
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/layouts/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              this.id = r.data.id;
              this.initialize();
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style lang="css">
.numbers {
  font-size: 1rem;
  color: #ffffff;
  height: 100%;
  text-align: center;
  width: 100%;
  padding-right: 12px;
}

.bus {
  border: 1px solid #aaa;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.intro {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: 200px;
  position: relative;
  left: -80px;
}

.linhas {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.aprox {
  position: relative;
  top: -20px;
}

.motorist {
  height: 210px;
  width: 200px;
}

.total {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.text {
  color: #fff;
  position: absolute;
  left: -3px;
  font-size: 1.3em;
  top: -11px;
  z-index: 998;
  font-weight: bold;
}

.legends {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.legends li {
  padding: 10px;
  width: 150px;
  text-align: center;
  color: #4e4d4d;
  font-weight: 400;
}

.custom-solo-selection .v-input__control {
  min-height: 0 !important;
}

.custom-solo-selection .v-input__slot {
  padding: 2.5px 12px !important;
}

.custom-solo-selection .v-input__slot {
  box-shadow: none !important;
  background-color: #b3e6f8 !important;
}

.custom-solo-selection .v-text-field__details {
  margin-bottom: 0 !important;
}

.container-form {
  position: relative;
  min-height: calc(100vh - 70px);
}

.footer-form {
  width: calc(100% - 24px) !important;
  margin: 0 auto;
}

@media only screen and (max-width: 1300px) {
  .bus {
    height: auto;
  }
}
</style>
